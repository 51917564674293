import request from "@/util/request";
import {getPaginatedResult} from "@/services/paginationHelpers";

export const EquipmentRepositoryInterface = {
  find() {
  },
  findById() {
  },
  list() {
  },
  create() {
  },
  delete() {
  },
  edit() {
  },

  // condensers
  listCondensers() {
  },
  crateCondenser() {
  },
  editCondenser() {
  },
  deleteCondenser() {
  },

  // condensers
  listEvaporators() {
  },
  crateEvaporator() {
  },
  editEvaporator() {
  },
  deleteEvaporator() {
  },

  // tools
  createTool() {
  },
  editTool() {
  },
  deleteTool() {
  },

  //upload
  upload() {    
  }
};

const endpoint = "/api/equipment";

export default {
  find(data) {
    return getPaginatedResult(endpoint, data);
  },
  list() {
    return request.get(endpoint);
  },
  create(data) {
    return request.post(endpoint, data);
  },
  findById(id) {
    return request.get(`${endpoint}/${id}`);
  },
  delete(id) {
    return request.delete(`${endpoint}/${id}`);
  },
  edit(code, data) {
    const body = Object.assign({}, data)
    body.client = body.client.id
    return request.put(`${endpoint}/${code}`, body);
  },

  // Condensers
  listCondensers(data) {
    return getPaginatedResult(`${endpoint}/condenser`, data);
  },
  crateCondenser(data) {
    // Cleaning presures values
    if (!_isFloat(data.lowPressure))
      data.lowPressure = null
    if (!_isFloat(data.highPressure))
      data.highPressure = null

    return request.post(`${endpoint}/condenser`, data);
  },
  editCondenser(data) {
    // Cleaning presures values
    if (!_isFloat(data.lowPressure))
      data.lowPressure = null
    if (!_isFloat(data.highPressure))
      data.highPressure = null

    return request.put(`${endpoint}/condenser`, data);
  },
  deleteCondenser(code) {
    return request.delete(`${endpoint}/condenser/${code}`);
  },

  // Evaporators
  listEvaporators(data) {
    return getPaginatedResult(`${endpoint}/evaporator`, data);
  },
  crateEvaporator(data) {
    return request.post(`${endpoint}/evaporator`, data);
  },
  editEvaporator(data) {
    return request.put(`${endpoint}/evaporator`, data);
  },
  deleteEvaporator(code) {
    return request.delete(`${endpoint}/evaporator/${code}`);
  },

  // Tools
  createTool(data) {
    return request.post("/api/library/tool", data);
  },
  editTool(data) {
    return request.put(`/api/library/tool/${data.code}`, data);
  },
  deleteTool(code) {
    return request.delete(`/api/library/tool/${code}`);
  },

  //upload
  upload(file) {
    return request.post("/api/upload/equipments", file);
  },
};

function _isFloat(value) {
  return !isNaN(parseFloat(value))
}