import {LibraryInterface} from "@/repositories/library";
import {UserRepositoryInterface} from "@/repositories/user";
import {OrderRepositoryInterface} from "@/repositories/order";
import {EquipmentRepositoryInterface} from "@/repositories/equipment";
import {ClientRepositoryInterface} from "@/repositories/client";

function bind(repositoryFactory, Interface) {
  return {
    ...Object.keys(Interface).reduce((prev, method) => {
      const resolvableMethod = async (...args) => {
        const repository = await repositoryFactory();
        return repository.default[method](...args);
      };
      return { ...prev, [method]: resolvableMethod };
    }, {}),
  };
}

export default {
  // get productRepository() {
  //     // Delay loading until a method of the repository is called.
  //     return bind(() => import('./repositories/product'), RepositoryInterface);
  // },
  // get userRepository() {
  //     // Load the repository immediately when it's injected.
  //     const userRepositoryPromise = import('./repositories/user');
  //     return bind(() => userRepositoryPromise, RepositoryInterface);
  // },
  get orderRepository() {
    const orderRepositoryPromise = import("./repositories/order");
    return bind(() => orderRepositoryPromise, OrderRepositoryInterface);
  },
  get userRepository() {
    const userRepositoryPromise = import("./repositories/user");
    return bind(() => userRepositoryPromise, UserRepositoryInterface);
  },
  get equipmentRepository() {
    const equipmentRepositoryPromise = import("./repositories/equipment");
    return bind(() => equipmentRepositoryPromise, EquipmentRepositoryInterface);
  },
  get clientRepository() {
    const clientRepositoryPromise = import("./repositories/client");
    return bind(() => clientRepositoryPromise, ClientRepositoryInterface);
  },
  get libraryRepository() {
    const libraryRepositoryPromise = import("./repositories/library");
    return bind(() => libraryRepositoryPromise, LibraryInterface);
  },
};
