import Vue from "vue";
import Vuex from "vuex";

import auth from "@/store/modules/auth";
import order from "@/store/modules/order";
import equipment from "@/store/modules/equipment";
import library from "@/store/modules/library";
import employee from "@/store/modules/employee";
import client from "@/store/modules/client";

Vue.use(Vuex);

// initial state
const initState = () => ({
  loader: false
});

export const state = initState;

export default new Vuex.Store({
  state,
  mutations: {
    LOADER(state, payload) {
      state.loader = payload
    },
  },
  actions: {},
  modules: {
    auth,
    order,
    equipment,
    library,
    employee,
    client,
  },
});
