<template>
  <div>{{ currentMessage }}</div>
</template>

<script>
import {
  ApplicationPaths,
  QueryParameterNames,
} from "@/components/api-authorization/ApiAuthorizationConstants";
import { AuthenticationResultStatus } from "@/services/authorizeService";

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export default {
  name: "Logout",
  data() {
    return {
      message: "",
      ready: false,
      authenticated: false,
    };
  },
  computed: {
    isReady() {
      return this.ready;
    },
    isAuthenticated() {
      return this.authenticated;
    },
    currentMessage() {
      return this.message;
    },
  },
  async created() {
    switch (this.$route.path) {
      case ApplicationPaths.LogOut:
        if (!window.history.state.local) {
          await this.logout(this.getReturnUrl());
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          this.ready = true;
          this.message = "The logout was not initiated from within the page.";
        }
        break;
      case ApplicationPaths.LogOutCallback:
        await this.processLogoutCallback();
        break;
      case ApplicationPaths.LoggedOut:
        this.ready = true;
        this.message = "You successfully logged out!";
        break;
      default:
        throw new Error(`Invalid action ${this.$route.path}`);
    }

    await this.populateAuthenticationState();
  },
  methods: {
    async logout(returnUrl) {
      const state = { returnUrl };
      const isAuthenticated = await this.auth.isAuthenticated();
      if (isAuthenticated) {
        const result = await this.auth.signOut(state);
        switch (result.status) {
          case AuthenticationResultStatus.Redirect:
            break;
          case AuthenticationResultStatus.Success:
            await this.navigateToReturnUrl(returnUrl);
            break;
          case AuthenticationResultStatus.Fail:
            this.message = result.message;
            break;
          default:
            throw new Error("Invalid authentication result status.");
        }
      } else {
        this.message = "You successfully logged out!";
      }
    },
    async processLogoutCallback() {
      const url = window.location.href;
      const result = await this.auth.completeSignOut(url);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeAuthentication finishes
          // is when we are doing a redirect sign in flow.
          throw new Error("Should not redirect.");
        case AuthenticationResultStatus.Success:
          await this.navigateToReturnUrl(this.getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          this.message = result.message;
          break;
        default:
          throw new Error("Invalid authentication result status.");
      }
    },
    async populateAuthenticationState() {
      const authenticated = await this.auth.isAuthenticated();
      this.ready = true;
      this.authenticated = authenticated;
    },
    getReturnUrl(state) {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(QueryParameterNames.ReturnUrl);
      if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error(
          "Invalid return url. The return url needs to have the same origin as the current page."
        );
      }
      return (
        (state && state.returnUrl) ||
        fromQuery ||
        `${window.location.origin}${ApplicationPaths.LoggedOut}`
      );
    },

    navigateToReturnUrl(returnUrl) {
      return window.location.replace(returnUrl);
    },
  },
};
</script>

<style scoped></style>
