import request from "@/util/request";

export async function getPaginatedResult(url, params) {
  const paginatedResult = {
    result: null,
    pagination: {
      currentPage: null,
      itemsPerPage: null,
      totalItems: null,
      totalPages: null,
    },
  };
  const response = await request.get(url, { params });
  paginatedResult.result = response.data;
  if (response.headers["pagination"] !== null) {
    paginatedResult.pagination = JSON.parse(response.headers["pagination"]);
  }

  return paginatedResult;
}
