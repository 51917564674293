import _ from 'lodash'
import request from "@/util/request";
import { getPaginatedResult } from "@/services/paginationHelpers";

export const OrderRepositoryInterface = {
  find() {
  },
  findById() {
  },
  list() {
  },
  create() {
  },
  delete() {
  },
  edit() {
  },
  approve() {
  },
  reject() {
  },
  rollback() {
  },
};

const endpoint = "/api/order";

export default {
  find(data) {
    return getPaginatedResult(endpoint, data);
  },
  findById(id) {
    return request.get(`${endpoint}/${id}`)
  },
  list(data) {
    return getPaginatedResult(endpoint, data);
  },
  create(data) {
    const order = Object.assign({}, data)
    order.client = data.client.id
    order.equipment = _.map(data.equipment, (item) => item.code)
    order.leader = data.leader.id
    order.office = data.office.id
    order.workers = data.workers.map(v => v.id)

    const startDate = `${data.startDate} ${data.startTime}`
    const endDate = `${data.endDate} ${data.endTime}`

    order.startDate = new Date(startDate)
    order.endDate = new Date(endDate)

    return request.post(endpoint, order);
  },
  delete(id) {
    return request.delete(`${endpoint}/${id}`);
  },
  edit(id, data) {
    const order = Object.assign({}, data)
    order.client = data.client.id
    order.equipment = data.equipment.code
    order.leader = data.leader.id
    order.workers = data.workers.map(v => v.id)

    const startDate = `${data.startDate} ${data.startTime}`
    const endDate = `${data.endDate} ${data.endTime}`

    order.startDate = new Date(startDate)
    order.endDate = new Date(endDate)

    return request.put(`${endpoint}/${id}`, order);
  },
  approve(id) {
    return request.post(`${endpoint}/${id}/approve`, {});
  },
  reject(id) {
    return request.post(`${endpoint}/${id}/reject`, {});
  },
  rollback(id) {
    return request.put(`${endpoint}/${id}/rollback`, {});
  },
};
