<template>
  <v-navigation-drawer
    v-model="drawer"
    :dark="$vuetify.dark"
    :mini-variant.sync="mini"
    :width="drawerWidth"
    app
    class="app-drawer"
  >
    <v-toolbar color="primary darken-1" dark>
      <img alt="Vue Material Admin Template" class="logo" height="36" src="/static/logo-sigman-white.png"/>
    </v-toolbar>
    <div class="app-drawer__inner">
      <v-list :dense="drawerWidth !== 64" class="pa-0">
        <template v-for="(item, key) in menus">
          <!--group with subitems-->
          <template v-if="item.children && item.children.length > 0">
            <v-list-group
              v-if="item.meta.requiredRoles ? requireRoles(item.meta.requiredRoles) : true"
              :key="key"
              :group="item.meta.group"
              :to="item.path"
              no-action
            >
              <template v-slot:prependIcon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon"/>
                  </template>
                  <span>
                    {{ item.meta.title }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.meta.title"/>
                </v-list-item-content>
              </template>
              <!--sub group-->
              <v-list-item
                v-for="subItem in item.children"
                v-show="!subItem.meta.hiddenInMenu && (subItem.meta.requiredRoles ? requireRoles(subItem.meta.requiredRoles) : true)"
                :key="subItem.name"
                :class="drawerWidth === 64 ? 'pl-4' : ''"
                :to="subItem.path"
              >
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-text="subItem.meta.title"/>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-group>
          </template>
          <template v-else-if="item.meta.requiredRoles ? requireRoles(item.meta.requiredRoles) : true">
            <v-list-item
              v-show="!item.meta.hiddenInMenu"
              :key="key"
              :to="item.path"
            >
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon"/>
                  </template>
                  <span>{{ item.meta.title }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content v-if="drawerWidth !== 64">
                <v-list-item-title v-text="item.meta.title"/>
              </v-list-item-content>
              <v-list-item-action v-if="item.meta.new">
                <v-icon color="green">
                  mdi-new-box
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>
<script>
import {routes} from "@/router";
import {mapGetters} from "vuex";

export default {
  name: "AppDrawer",
  components: {},
  props: {
    expanded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menus: [],
      mini: false,
      drawerWidth: 256,
      drawer: true,
      scrollSettings: {
        maxScrollbarLength: 160,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['roles'])
  },
  created() {
    this.menus = routes[11].children
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    requireRoles(roles) {
      return roles.some(e => this.roles.indexOf(e) !== -1)
    }
  },
};
</script>

<style lang="sass" scoped>
.app-drawer
  overflow: hidden !important

  &__inner
    height: calc(100vh - 100px)
  //overflow-y: scroll

  .drawer-menu--scroll
    height: calc(100vh - 48px)
    overflow: auto

.logo
  display: block
  margin-left: auto
  margin-right: auto
  width: 60%

//.list-group__header.v-list-item.v-list-item--active,
//.v-list-item--active.v-list-item 
//  background-color: #00BFD7
//  color: white

</style>
