import {getPaginatedResult} from "@/services/paginationHelpers";
import request from "@/util/request";

export const LibraryInterface = {
  fetchEstates() {
  },
  fetchCities() {
  },
  fetchStatuses() {
  },
  fetchEmployees() {
  },
  fetchClients() {
  },
  fetchOrders() {
  },
  fetchStats() {
  },
  fetchTools() {
  }
};

const endpoint = "/api/library";

export default {
  fetchEstates(data) {
    return getPaginatedResult(`${endpoint}/estates`, data);
  },
  fetchCities(data) {
    return getPaginatedResult(`${endpoint}/locations`, data);
  },
  fetchStatuses(data) {
    return getPaginatedResult(`${endpoint}/statuses`, data);
  },
  fetchEmployees(data) {
    return getPaginatedResult(`${endpoint}/employees`, data);
  },
  fetchClients(data) {
    return request.get(`${endpoint}/clients`, { params: data });
  },
  fetchOrders(data) {
    return getPaginatedResult(`${endpoint}/orders`, data);
  },
  fetchStats() {
    return request.get(`${endpoint}/stats`);
  },
  fetchTools(data) {
    return getPaginatedResult(`${endpoint}/tools`, data);
  }
};