<template>
  <v-app :dark="true">
    <router-view/>
    <!-- global snackbar -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
      app
      centered
      multi-line
      top
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackbar.show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <loader></loader>
  </v-app>
</template>

<script>
import EventBus from "@/util/eventbus";
import Loader from '@/components/_loader';

export default {
  components: { Loader },
  data() {
    return {
      rightDrawer: false,
      snackbar: {
        show: false,
        text: "",
        color: "",
      },
    };
  },
  created() {
    EventBus.$on("SHOW_SNACKBAR", (e) => {
      this.snackbar = {
        show: true,
        text: e.text,
        color: e.color,
      };
    });
    EventBus.$on("AUTH_FAILED", () => {
      this.snackbar = {
        show: true,
        text: "Sesión expirada",
        color: "error",
      };
      this.$router.push({
        path: "/authentication/logout",
        query: {
          returnUrl: window.location.href,
        },
      });
    });
    EventBus.$on("SERVER_ERROR", () => {
      this.snackbar = {
        show: true,
        text: "Server Error",
        color: "error",
      };
    });
    EventBus.$on("ACCESS_DENIED", () => {
      this.snackbar = {
        show: true,
        text: "Operación no permitida",
        color: "error",
      };
      this.$router.replace({ name: "401" });
    });
  },
};
</script>

<style lang="sass" scoped>
.setting-fab
  top: 50% !important
  right: 0
  border-radius: 0
</style>
