import Vue from "vue";
import VueRouter from "vue-router";
import {protectedRoutes, publicRoutes} from "@/router/config";
import authorizeService from "@/services/authorizeService";

export const routes = [...publicRoutes, ...protectedRoutes];

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL || "/",
  routes,
  scrollBehavior: () => {
    return { x: 0, y: 0 };
    // if (to.hash) {
    //   return { selector: to.hash };
    // } else {
    //   return { x: 0, y: 0 };
    // }
  },
});

// navigation guards before each
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(value => value.meta && value.meta.requiresAuth === true)) {
    const isAuthenticated = await authorizeService.isAuthenticated()
    if (!isAuthenticated) {
      next({ name: 'login', query: { returnUrl: window.location.href } })
    } else {
      const userRoles = await authorizeService.getUserRoles()
      if (!to.matched.every(value => value.meta.requiredRoles
        ? value.meta.requiredRoles.some(role => userRoles.indexOf(role) !== -1)
        : true
      )) {
        next({ name: '401' })
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

// navigation guard after each
router.afterEach(() => {
});

export default router;
