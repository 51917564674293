import { render, staticRenderFns } from "./AppDrawer.vue?vue&type=template&id=effbc870&scoped=true&"
import script from "./AppDrawer.vue?vue&type=script&lang=js&"
export * from "./AppDrawer.vue?vue&type=script&lang=js&"
import style0 from "./AppDrawer.vue?vue&type=style&index=0&id=effbc870&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "effbc870",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemAction,VListItemContent,VListItemIcon,VListItemTitle,VNavigationDrawer,VToolbar,VTooltip})
