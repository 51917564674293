import authorizeService from "@/services/authorizeService";

export default function(Vue /*options*/) {
  Vue.auth = authorizeService;
  window.auth = authorizeService;
  Object.defineProperties(Vue.prototype, {
    auth: {
      get() {
        return authorizeService;
      },
    },
    $auth: {
      get() {
        return authorizeService;
      },
    },
  });
}
