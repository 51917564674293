import services from "@/service-container";

// initial state
const initState = () => ({
  equipments: [],
  equipmentList: [],
  condensers: [],
  evaporators: [],
  equipmentsUpload: []
});

export const state = initState;

const getters = {};

const mutations = {
  saveEquipments(store, { equipments }) {
    store.equipments = equipments;
  },
  addEquipment(store, { equipment }) {
    store.equipments.push(equipment);
  },
  saveEquipment(store, { equipment }) {
    const position = store.equipments.findIndex(value => value.id === equipment.id)
    if (position === -1) {
      store.equipments.push(equipment);
    } else {
      /* Replace item */
      store.equipments.splice(position, 1, equipment);
    }
  },
  deleteEquipment(store, { position }) {
    store.equipments.splice(position, 1);
  },
  toggleLoading(store) {
    store.loadingItems = !store.loadingItems;
  },
  saveEquipmentList(store, { equipments }) {
    store.equipmentList = equipments
  },
  // Condenser
  saveCondensers(store, { condensers }) {
    store.condensers = condensers
  },
  saveCondenser(store, { condenser }) {
    const position = store.condensers.findIndex(value => value.code === condenser.code)
    if (position === -1) {
      store.condensers.push(condenser);
    } else {
      /* Replace item */
      store.condensers.splice(position, 1, condenser);
    }
  },
  removeCondenser(store, { code }) {
    const position = store.condensers.findIndex(value => value.code === code)
    if (position !== -1)
      store.condensers.splice(position, 1)
  },

  // Evaporator
  saveEvaporators(store, { evaporators }) {
    store.evaporators = evaporators
  },
  saveEvaporator(store, { evaporator }) {
    const position = store.evaporators.findIndex(value => value.code === evaporator.code)
    if (position === -1) {
      store.evaporators.push(evaporator);
    } else {
      /* Replace item */
      store.evaporators.splice(position, 1, evaporator);
    }
  },
  removeEvaporator(store, { code }) {
    const position = store.evaporators.findIndex(value => value.code === code)
    if (position !== -1)
      store.evaporators.splice(position, 1)
  },

  uploadEquipments(store, { equipments }) {
    store.equipmentsUpload = equipments;
  },

  // Reset
  reset(store) {
    Object.assign(store, initState());
  },
};

const actions = {
  // Equipment
  async fetchEquipments({ commit }, query) {
    return await services.equipmentRepository
      .find(query)
      .then(({ result, pagination }) => {
        commit("saveEquipments", { equipments: result });
        return pagination
      })
      .catch(() => commit("reset"));
  },
  async getEquipment({ commit }, id) {
    return services.equipmentRepository
      .findById(id)
      .then(({ data }) => {
        commit("library/addClient", { client: data.client }, { root: true });
        return data
      })
  },
  async createEquipment({ commit }, item) {
    await services.equipmentRepository
      .create(item)
      .then(({ data }) => {
        commit("addEquipment", { equipment: data })
      });
  },
  async editEquipment({ commit }, { id, equipment }) {
    await services.equipmentRepository.edit(id, equipment)
      .then(() => commit('saveEquipment', { equipment }));
  },
  async deleteEquipment({ commit }, { item, position }) {
    await services.equipmentRepository
      .delete(item.code)
      .then(() => commit("deleteEquipment", { position }));
  },

  // Condenser
  fetchCondensers({ commit }, data) {
    return services.equipmentRepository
      .listCondensers(data)
      .then(({ result, pagination }) => {
        commit('saveCondensers', { condensers: result })
        return pagination;
      })
  },
  crateCondenser({ commit }, data) {
    return services.equipmentRepository
      .crateCondenser(data)
      .then(({ data }) => {
        commit('saveCondenser', { condenser: data })
      })
  },
  editCondenser({ commit }, data) {
    return services.equipmentRepository
      .editCondenser(data)
      .then(() => {
        commit('saveCondenser', { condenser: data })
      })
  },
  deleteCondenser({ commit }, code) {
    return services.equipmentRepository
      .deleteCondenser(code)
      .then(() => {
        commit('removeCondenser', { code })
      })
  },

  // Evaporators
  fetchEvaporators({ commit }, data) {
    return services.equipmentRepository
      .listEvaporators(data)
      .then(({ result, pagination }) => {
        commit('saveEvaporators', { evaporators: result })
        return pagination
      })
  },
  crateEvaporator({ commit }, data) {
    return services.equipmentRepository
      .crateEvaporator(data)
      .then(({ data }) => {
        commit('saveEvaporator', { evaporator: data })
      })
  },
  editEvaporator({ commit }, data) {
    return services.equipmentRepository
      .editEvaporator(data)
      .then(() => {
        commit('saveEvaporator', { evaporator: data })
      })
  },
  deleteEvaporator({ commit }, code) {
    return services.equipmentRepository
      .deleteEvaporator(code)
      .then(() => {
        commit('removeEvaporator', { code })
      })
  },

  //Upload
  async uploadEquipments({ commit }, file) {
    return services.equipmentRepository
      .upload(file)
      .then(({ data }) => {
        commit("uploadEquipments", { equipments: data });
        return data;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
