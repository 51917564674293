import services from "@/service-container";

// initial state
const initState = () => ({
  employees: [],
});

const state = initState;

const getters = {};
const mutations = {
  setEmployees(state, { employees }) {
    state.employees = employees;
  },
  addEmployee(state, { employee }) {
    state.employees.push(employee);
  },
  reset(state) {
    Object.assign(state, initState());
  },
};

const actions = {
  fetchEmployees({ commit }, data) {
    return services.userRepository
      .list(data)
      .then(({ result }) => {
        commit("setEmployees", { employees: result });
      })
      .catch(() => commit("reset"));
  },
  createEmployee(_, data) {
    return services.userRepository.create(data);
  },
  editEmployee(_, data) {
    return services.userRepository.edit(data);
  },
  fetchEmployee(_, id) {
    return services.userRepository
      .findById(id)
      .then(({ data }) => {
        return data
      })
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
