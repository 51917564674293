"use strict";

import Vue from "vue";
import request from "@/util/request";

Plugin.install = function(Vue /*options*/) {
  Vue.axios = request;
  window.axios = request;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return request;
      },
    },
    $axios: {
      get() {
        return request;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
