import request from "@/util/request";
import {getPaginatedResult} from "@/services/paginationHelpers";

const endpoint = "/api/user";

export const UserRepositoryInterface = {
  find() {
  },
  findById() {
  },
  list() {
  },
  create() {
  },
  delete() {
  },
  edit() {
  },
};

export default {
  find() {
    return request.get(endpoint);
  },
  list(data) {
    return getPaginatedResult(endpoint, data);
  },
  findById(id) {
    return request.get(`${endpoint}/${id}`);
  },
  create(data) {
    return request.post(endpoint, data);
  },
  edit(data) {
    return request.put(endpoint, data);
  },
  delete(id) {
    return request.delete(`${endpoint}/${id}`);
  },
};
