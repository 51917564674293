<template>
  <router-view />
</template>
<script>
  export default {
    name: "RouteWrapper",
  };
</script>

<style></style>
