// initial state
import authorizeService from "@/services/authorizeService";

const initState = () => ({
  user: null,
});

export const state = initState;

const getters = {
  authenticated: (state) => state.user != null,
  userName: (state) => state.user.profile.name || null,
  roles: (state) => Array.isArray(state.user.profile.role) ? state.user.profile.role : [state.user.profile.role],
  admin: (state, getters) => getters.roles.some(role => role === 'Admin'),
  supervisor: (state, getters) => getters.roles.some(role => role === 'Supervisor'),
  cliente: (state, getters) => getters.roles.some(role => role === 'Cliente')
};

const mutations = {
  saveUser(state, { user }) {
    state.user = user;
  },
  reset(state) {
    Object.assign(state, initState());
  },
};

const actions = {
  login() {
    authorizeService.signIn().then(() => {
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
