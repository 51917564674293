<template>
  <v-app class="app">
    <app-drawer ref="drawer" class="app--drawer" />
    <app-toolbar class="app--toolbar" @side-icon-click="handleDrawerVisible" />
    <v-main>
      <!-- Page Wrapper -->
      <div class="page-wrapper">
        <router-view />
      </div>
      <!-- App Footer -->
      <v-footer class="pa-3 app--footer" height="auto">
        <span>Cuantik Solutions &copy; {{ new Date().getFullYear() }}</span>
        <v-spacer />
        <span class="caption mr-1">version {{ version }}</span>
      </v-footer>
    </v-main>
    <!-- Go to top -->
    <app-fab />
  </v-app>
</template>

<script>
  import AppDrawer from "@/components/AppDrawer";
  import AppToolbar from "@/components/AppToolbar";
  import AppFab from "@/components/AppFab";

  export default {
    name: "LayoutDefault",
    components: {
      AppDrawer,
      AppToolbar,
      AppFab,
    },

    data() {
      return {
        showDrawer: true,
        version: process.env.VUE_APP_VERSION,
      };
    },
    methods: {
      handleDrawerVisible() {
        this.$refs.drawer.toggleDrawer();
      },
    },
  };
</script>

<style lang="sass" scoped>
  .page-wrapper
    min-height: calc(100vh - 112px - 48px)
</style>
