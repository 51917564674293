import services from "@/service-container";

// initial state
const initState = () => ({
    clients: [],
});

const state = initState;

const getters = {};
const mutations = {
    setClients(state, {clients}) {
        state.clients = clients;
    },
    saveClient(state, {client}) {
        state.clients.push(client);
    },
    editClient(state, {client, position}) {
        state.clients.splice(position, 1, client);
    },
    deleteClient(state, {client}) {
        const index = state.clients.indexOf(client);
        if (index !== -1) {
            state.clients.splice(index, 1,);
        }
    },
    reset(state) {
        Object.assign(state, initState());
    },
};

const actions = {
    async fetchClients({commit}, data) {
        return services.clientRepository
            .list(data)
            .then(({result, pagination}) => {
                commit("setClients", {clients: result});
                return pagination.totalItems;
            })
            .catch(() => commit("reset"));
    },
    async fetchClient({commit}, id) {
        commit('LOADER', true, {root: true})
        return services.clientRepository
            .findById(id)
            .then(({data}) => {
                if (data.supervisor)
                    commit("employee/setEmployees", {employees: [data.supervisor]}, {root: true});
                if (data.offices.length > 0) {
                    const locations = data.offices.map(office => office.location)
                    commit("library/setCities", {cities: locations}, {root: true});
                }

                return data
            })
            .finally(() => commit('LOADER', false, {root: true}));
    },
    async deleteClient({commit}, client) {
        return services.clientRepository
            .delete(client.id)
            .then(() => {
                commit('deleteClient', {client})
            })
    },
    async createEmployee({commit}, data) {
        return services.clientRepository.create(data).then(() => {
            commit("saveClient", {client: data});
        });
    },
    async editEmployee(_, { id, employee }) {
        return services.clientRepository.edit(id, employee);
    },
    async clientOffices({commit}, data) {
        return services.clientRepository
            .listOffices(data)
            .then(({result, pagination}) => {
                commit("setClients", {clients: result});
                return pagination.totalItems;
            })
            .catch(() => commit("reset"));
    },
    async createOffice(_, {clientId, data}) {
        return services.clientRepository.createOffice(clientId, data)
            .then(({data}) => data);
    },
    async editOffice(_, {clientId, data}) {
        return services.clientRepository.editOffice(clientId, data)
            .then(({data}) => data);
    },
    async deleteOffice(_, {clientId, data}) {
        return services.clientRepository.deleteOffice(clientId, data.id)
            .then(({data}) => data);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
