// initial state
import services from "@/service-container";

const orderState = ['Created', 'InAction', 'Finished', 'Approved', 'Rejected']

const initState = () => ({
    orders: [],
    stats: [
      { description: orderState[0], value: 0 },
      { description: orderState[1], value: 0 },
      { description: orderState[2], value: 0 },
      { description: orderState[3], value: 0 },
      { description: orderState[4], value: 0 },
    ],
    dictionary: {
      cities: {},
      employees: {},
      clients: {},
      tools: {},
    },
    orderTypes: [
      { id: 1, value: 'Mantenimiento correctivo', icon: 'mdi-alert' },
      { id: 2, value: 'Mantenimiento preventivo', icon: 'mdi-calendar' },
      { id: 3, value: 'Instalación de equipos', icon: 'mdi-new-box' },
    ],
    equipmentTypes: [
      { id: 1, value: 'SPLIT DUCTO' },
      { id: 2, value: 'SPLIT DE PARED' },
      { id: 3, value: 'SPLIT CASSETE' },
      { id: 4, value: 'SPLIT FAN COIL' },
      { id: 5, value: 'PAQUETE' },
      { id: 6, value: 'CONDENSADORA_VRF'}, 	
      { id: 7, value: 'EVAPORADORA_VRF'}, 		
      { id: 8, value: 'VENTILADOR DE INYECCION'},
      { id: 9, value: 'VENTILADOR DE EXTRACCION'}, 
      { id: 10, value: 'CORTINASDE AIRE'}, 
      { id: 11, value: 'PISO TECHO'}, 
    ],
    orderState
  })
;

const state = initState;

const getters = {
  total: state => {
    return state.stats.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.value;
    }, 0);
  },
};

const mutations = {
  reset(state) {
    Object.assign(state, initState());
  },
  setCities(state, { cities }) {
    cities.forEach(city => state.dictionary.cities[city.slug] = city)
  },
  setEmployees(state, { employees }) {
    employees.forEach(employee => state.dictionary.employees[employee.id] = employee)
  },
  setClients(state, { clients }) {
    clients.forEach(client => state.dictionary.clients[client.id] = client)
  },
  setOrders(state, { orders }) {
    state.orders = orders
  },
  setStats(state, { stats }) {
    stats.forEach(stat => state.stats[stat.state - 1].value = stat.count)
  },
  addCity(state, { city }) {
    state.dictionary.cities[city.slug] = city
  },
  addEmployee(state, { employee }) {
    state.dictionary.employees[employee.id] = employee
  },
  addClient(state, { client }) {
    state.dictionary.clients[client.id] = client
  },
  setTools(state, { tools }) {
    tools.forEach(tool => state.dictionary.tools[tool.code] = tool)
  },
  addTool(state, { tool }) {
    state.dictionary.tools[tool.code] = tool
  },
  deleteTool(state, { code }) {
    delete state.dictionary.tools[code]
  },
};

const actions = {
  async fetchCities({ commit }, data) {
    return await services.libraryRepository
      .fetchCities(data)
      .then(({ result, pagination }) => {
        commit("setCities", { cities: result });
        return pagination.totalItems;
      });
  },
  // Employees
  async fetchEmployees({ commit }, data) {
    return await services.libraryRepository
      .fetchEmployees(data)
      .then(({ result, pagination }) => {
        commit("setEmployees", { employees: result });
        return pagination.totalItems;
      });
  },
  async fetchClients({ commit }, data) {
    return await services.libraryRepository
      .fetchClients(data)
      .then(({ data }) => {
        commit("setClients", { clients: data });
      });
  },
  async fetchOrders({ commit }, data) {
    return await services.libraryRepository
      .fetchOrders(data)
      .then(({ result, pagination }) => {
        commit("setOrders", { orders: result });
        return pagination.totalItems;
      });
  },
  async fetchStats({ commit }) {
    commit("reset");
    commit('LOADER', true, { root: true })
    return await services.libraryRepository
      .fetchStats()
      .then(({ data }) => {
        commit("setStats", { stats: data });
      }).finally(() => commit('LOADER', false, { root: true }));
  },
  // Tools
  async fetchTools({ commit }, data) {
    commit("reset");
    return await services.libraryRepository
      .fetchTools(data)
      .then(({ result, pagination }) => {
        commit("setTools", { tools: result });
        return pagination.totalItems;
      });
  },
  async createTool({ commit }, data) {
    return await services.equipmentRepository
      .createTool(data)
      .then(({ data }) => {
        commit("addTool", { tool: data });
      });
  },
  async editTool({ commit }, data) {
    return await services.equipmentRepository
      .editTool(data)
      .then(({ data }) => {
        commit("addTool", { tool: data });
      });
  },
  async deleteTool({ commit }, code) {
    return await services.equipmentRepository
      .deleteTool(code)
      .then(() => {
        commit("deleteTool", { code });
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
