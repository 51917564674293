import Vue from "vue";
import Vuetify from "vuetify/lib";
import es from "vuetify/es5/locale/es";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#005CAE",
        secondary: "#424242",
        accent: "#00AEEF",
        error: "#FF3739",
        info: "#00D0BD",
        success: "#00D014",
        warning: "#FFB70F",
        light_caption: "#BDBDBD",
        light_icon: "#E0E0E0",
        dark_caption: "#757575",
        dark_title: "#1A237E",
        
        // primary: '#00BFD7', //MEDIUM_BLUE
        // secondary: '#003245', //DARK_BLUE
        // // accent: '#82B1FF',
        // // accent: '#FAFAFA',
        // yellow: '#FFAD00',
        // purple: '#980098',
        // daintree: '#1E3245',
        // orange: '#E25D32',
        // cream: '#F1E9B2',
        // beige: '#C2BB92',
        // white: '#ffffff',
        //
        //
        // accent: '#1A237E',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FFC107',
        //
        // gray5: '#50535A',
        // gray4: '#86888C',
        // gray3: '#B6B7B7',
        // gray2: '#D3D4D3',
        // gray1: '#E9EAE8',
      },
    },
  },
  lang: {
    locales: { es, en },
    current: "es",
    fallbackLocale: en,
  },
});
