import store from "@/store";
import {UserManager, WebStorageStateStore,} from "oidc-client";

export const AuthenticationResultStatus = {
  Success: "success",
  Redirect: "redirect",
  Fail: "fail",
};

function AuthorizeService() {
  this._popUpDisabled = true;
  this._settings = {
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    authority: process.env.VUE_APP_STS_DOMAIN,
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_REDIRECT_URI,
    silent_redirect_uri: process.env.VUE_APP_REDIRECT_URI,
    post_logout_redirect_uri: process.env.VUE_APP_LOGOUT_REDIRECT_URI,
    automaticSilentRenew: true,
    includeIdTokenInSilentRenew: true,
    response_type: "code",
    scope: "openid profile IdentityServerApi roles",
    filterProtocolClaims: true,
    revokeAccessTokenOnSignout: true
  };

  this.ensureUserManagerInitialized = function () {
    if (this.userManager !== undefined) {
      return;
    }

    this.userManager = new UserManager(this._settings);

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager.removeUser();
    });
  };

  // We try to authenticate the user in three different ways:
  // 1) We try to see if we can authenticate the user silently. This happens
  //    when the user is already logged in on the IdP and is done using a hidden iframe
  //    on the client.
  // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
  //    redirect flow.
  this.signIn = async function (state) {
    this.ensureUserManagerInitialized();
    // let user = null
    try {
      const silentUser = await this.userManager.signinSilent(
        this.createArguments()
      );
      store.commit("auth/saveUser", { user: silentUser });
      return this.success(state);
    } catch (silentError) {
      // User might not be authenticated, fallback to popup authentication
      try {
        if (this._popUpDisabled) {
          throw new Error(
            "Popup disabled. Change 'oidc.js:AuthorizeService.popupDisabled' to false to enable it."
          );
        }
        const popUpUser = await this.userManager.signinPopup(
          this.createArguments()
        );
        store.commit("auth/saveUser", { user: popUpUser });
        return this.success(state);
      } catch (popupError) {
        if (popupError.message === "Popup window closed") {
          // The user explicitly cancelled the login action by closing an opened popup.
          return this.error("The user closed the window.");
        } else if (!this._popUpDisabled) {
          console.log("Popup authentication error: ", popupError);
        }

        // PopUps might be blocked by the user, fallback to redirect
        try {
          await this.userManager.signinRedirect(this.createArguments(state));
          return this.redirect();
        } catch (redirectError) {
          console.log("Redirect authentication error: ", redirectError);
          return this.error(redirectError);
        }
      }
    }
  };

  this.completeSignIn = async function (url) {
    try {
      this.ensureUserManagerInitialized();
      const user = await this.userManager.signinCallback(url);
      store.commit("auth/saveUser", { user });
      return this.success(user && user.state);
    } catch (error) {
      console.log("There was an error signing in: ", error);
      return this.error("There was an error signing in.");
    }
  };

  this.signOut = async function (state) {
    this.ensureUserManagerInitialized();

    try {
      if (this._popUpDisabled) {
        throw new Error(
          "Popup disabled. Change 'authorize.service.ts:AuthorizeService.popupDisabled' to false to enable it."
        );
      }

      await this.userManager.signoutPopup(this.createArguments());
      return this.success(state);
    } catch (popupSignOutError) {
      console.log("Popup signout error: ", popupSignOutError);
      try {
        await this.userManager.signoutRedirect(this.createArguments(state));
        return this.redirect.call();
      } catch (redirectSignOutError) {
        console.log("Redirect signout error: ", popupSignOutError);
        return this.error(redirectSignOutError);
      }
    }
  };

  this.completeSignOut = async function (url) {
    this.ensureUserManagerInitialized();
    try {
      const response = await this.userManager.signoutCallback(url);
      return this.success(response && response.state);
    } catch (error) {
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  };

  this.success = function (state) {
    return { status: AuthenticationResultStatus.Success, state };
  };

  this.redirect = function () {
    return { status: AuthenticationResultStatus.Redirect };
  };

  this.error = function (message) {
    return { status: AuthenticationResultStatus.Fail, message };
  };

  this.createArguments = function (state) {
    return { useReplaceToNavigate: true, data: state };
  };

  this.getAccessToken = async function () {
    const user = await this.getUserFromStorage();
    return user && user.access_token;
  };

  this.getUserFromStorage = function () {
    this.ensureUserManagerInitialized();
    return this.userManager.getUser();
  };

  this.getUser = async function () {
    if (store.state.auth.user && store.state.auth.user.profile) {
      return store.state.auth.user.profile;
    }

    const user = await this.getUserFromStorage();
    store.commit("auth/saveUser", { user });
    return user && user.profile;
  };

  this.isAuthenticated = async function () {
    const user = await this.getUser();
    return !!user;
  };

  this.getUserRoles = async function () {
    const user = await this.getUser();
    const value = user['role']
    return Array.isArray(value) ? value : [value];
  }
}

const _authService = new AuthorizeService();

export default _authService;
