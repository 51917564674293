import request from "@/util/request";
import {getPaginatedResult} from "@/services/paginationHelpers";

export const ClientRepositoryInterface = {
  find() {
  },
  findById() {
  },
  list() {
  },
  create() {
  },
  delete() {
  },
  edit() {
  },
  listOffices() {
  },
  createOffice() {
  },
  deleteOffice() {
  },
  editOffice() {
  },
  orders() {
  },
  grantWebAccess() {
  },
  revokeWebAccess() {
  }
};

const endpoint = "/api/client";

export default {
  find(data) {
    return request.get(endpoint, data);
  },
  findById(id) {
    return request.get(`${endpoint}/${id}`);
  },
  list(data) {
    return getPaginatedResult(endpoint, data);
  },
  create(data) {
    const client = Object.assign({}, data)
    client.supervisor = data.supervisor.id

    return request.post(endpoint, client);
  },
  delete(code) {
    return request.delete(`${endpoint}/${code}`);
  },
  edit(id, data) {
    const client = Object.assign({}, data)
    client.supervisor = data.supervisor.id

    return request.put(`${endpoint}/${id}`, client);
  },
  listOffices(data) {
    return getPaginatedResult(`${endpoint}/office`, data);
  },
  createOffice(clientId, data) {
    if (data.location)
      data.location = data.location.slug
    return request.post(`${endpoint}/${clientId}/office`, data);
  },
  deleteOffice(clientId, officeId) {
    return request.delete(`${endpoint}/${clientId}/office/${officeId}`);
  },
  editOffice(clientId, data) {
    const temp = Object.assign({}, data)
    temp.location = data.location.slug

    return request.put(`${endpoint}/${clientId}/office/${data.id}`, temp);
  },
  orders(data){
    return getPaginatedResult(`${endpoint}/orders`, data);
  },
  grantWebAccess(clientId){
    return request.put(`${endpoint}/${clientId}/grant-web-access`);
  },
  revokeWebAccess(clientId){
    return request.put(`${endpoint}/${clientId}/revoke-web-access`);
  }
};
