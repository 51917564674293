import services from "@/service-container";

// initial state
const initState = () => ({
  orders: [],
});

export const state = initState;

const getters = {};
const mutations = {
  saveOrders(state, {orders}) {
    state.orders = orders;
  },
  addOrder(state, {order}) {
    state.orders.push(order);
  },
  deleteOrder(state, {position}) {
    state.orders.splice(position, 1);
  }
};
const actions = {
  fetchOrders({commit}, query) {
    return services.orderRepository.list(query)
      .then(({pagination, result}) => {
        commit("saveOrders", {orders: result});
        return pagination.totalItems
      })
  },
  findOrder({commit}, id) {
    return services.orderRepository.findById(id)
      .then(({data: order}) => {
        commit("library/reset", null, {root: true});
        commit("library/addClient", {client: order.client}, {root: true});
        commit("library/setEmployees", {employees: [order.leader, ...order.workers]}, {root: true});
        commit("equipment/saveEquipmentList", {equipments: [order.equipment]}, {root: true});
        return order
      });
  },
  createOrder({commit}, order) {
    return services.orderRepository.create(order)
      .then(() => {
        commit("addOrder", {orders: order});
      });
  },
  editOrder({commit}, {id, order}) {
    return services.orderRepository.edit(id, order)
      .then(() => {
        commit("addOrder", {orders: order});
      });
  },
  deleteOrder({commit}, {item, position}) {
    return services.orderRepository.delete(item.id)
      .then(() => commit("deleteOrder", {position}))
  },
  approveOrder(_, {orderId}) {
    return services.orderRepository.approve(orderId)
  },
  rejectOrder(_, {orderId}) {
    return services.orderRepository.reject(orderId)
  },
  rollbackOrder(_, {orderId}) {
    return services.orderRepository.rollback(orderId)
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
