import RouteWrapper from "@/components/layouts/RouteWrapper";
import Login from "@/components/api-authorization/Login";
import Logout from "@/components/api-authorization/Logout";
import LayoutDefault from "@/components/layouts/LayoutDefault";

import {ApplicationPaths} from "@/components/api-authorization/ApiAuthorizationConstants";

export const publicRoutes = [
  // Authentication
  { path: ApplicationPaths.Register, component: Login },
  { path: ApplicationPaths.Profile, component: Login },
  { path: ApplicationPaths.Login, component: Login, name: "login" },
  { path: ApplicationPaths.LoginFailed, component: Login },
  { path: ApplicationPaths.LoginCallback, component: Login },
  { path: ApplicationPaths.LogOut, component: Logout },
  { path: ApplicationPaths.LoggedOut, component: Logout },
  { path: ApplicationPaths.LogOutCallback, component: Logout },
  {
    path: '/error',
    name: '500',
    meta: { title: 'Error inesperado' },
    component: () => import('@/views/error/Error.vue')
  },
  {
    path: '/deny',
    name: '401',
    meta: { title: 'Acceso denegado' },
    component: () => import('@/views/error/Deny')
  },
  { path: '*', name: 'notfound', component: () => import('@/views/error/NotFound.vue') },
]

export const protectedRoutes = [
  {
    path: "/",
    component: LayoutDefault,
    meta: {
      title: "Inicio",
      group: "apps",
      icon: "",
      hiddenInMenu: false,
      requiresAuth: true,
    },
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "Principal",
          group: "apps",
          icon: "mdi-view-dashboard",
        },
        component: () => import(/* webpackChunkName: "d" */ "@/views/dashboard/DashboardC"),
      },
      {
        path: "/o",
        name: "order.list",
        meta: {
          title: "Orden de trabajo",
          group: "apps",
          icon: "mdi-clipboard-file-outline",
          hiddenInMenu: false,
          requiredRoles: ['Admin', 'Supervisor']
        },
        component: () =>
          import(/* webpackChunkName: "ol" */ "@/views/order/OrderList"),
      },
      {
        path: "/o/create",
        name: "order.create",
        meta: {
          title: "Nueva Orden de trabajo",
          group: "apps",
          icon: "",
          hiddenInMenu: true,
        },
        component: () =>
          import(/* webpackChunkName: "ov" */ "@/views/order/OrderView"),
      },
      {
        path: "/o/:id/edit",
        name: "order.edit",
        meta: {
          title: "Editar Orden de trabajo",
          group: "apps",
          icon: "",
          hiddenInMenu: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "ov" */ "@/views/order/OrderView"
            ),
      },
      {
        path: "/o/:id/images",
        name: "order.images",
        meta: {
          title: "Imágenes de la Orden de trabajo",
          group: "apps",
          icon: "",
          hiddenInMenu: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "oi" */ "@/views/order/OrderImages"
            ),
      },
      {
        path: "/reports",
        name: "report",
        meta: {
          title: "Reportes",
          group: "apps",
          icon: "mdi-finance",
          hiddenInMenu: true,
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/About"),
      },
      {
        path: "/adm",
        component: RouteWrapper,
        redirect: "/adm/u",
        meta: {
          title: "Administración",
          icon: "mdi-shield-half-full",
          group: "adm",
          requiredRoles: ['Admin', 'Supervisor']
        },
        children: [
          {
            path: "/adm/u",
            name: "admin.personal.list",
            meta: {
              title: "Directorio de trabajadores",
              icon: "mdi-account-multiple",
              group: "adm",
              requiredRoles: ['Admin']
            },
            component: () => import(/* webpackChunkName: "ul" */ "@/views/adm/user/UserList"),
          },
          {
            path: "/adm/u/create",
            name: "admin.personal.create",
            meta: {
              title: "Nuevo Trabajador",
              icon: "",
              hiddenInMenu: true,
              requiredRoles: ['Admin']
            },
            component: () => import(/* webpackChunkName: "uc" */ "@/views/adm/user/UserCreate"),
          },
          {
            path: "/adm/u/:id/edit/",
            name: "admin.personal.edit",
            meta: {
              title: "Editar usuario",
              icon: "",
              hiddenInMenu: true,
              requiredRoles: ['Admin']
            },
            props: true,
            component: () => import(/* webpackChunkName: "uct" */ "@/views/adm/user/UserCreate"),
          },
          {
            path: "/adm/c",
            component: RouteWrapper,
            redirect: "/adm/c",
            meta: {
              title: "Clientes",
              icon: "mdi-card-account-details",
              hiddenInMenu: false,
              group: "adm",
              requiredRoles: ['Admin', 'Supervisor']
            },
            children: [
              {
                path: "/adm/c",
                name: "admin.client.list",
                meta: {
                  title: "Lista de Clientes",
                  icon: "",
                  group: "adm",
                  requiredRoles: ['Admin', 'Supervisor']
                },
                component: () => import(/* webpackChunkName: "cll" */ "@/views/adm/client/ClientList"),
              },
              {
                path: "/adm/c/create",
                name: "admin.client.create",
                meta: {
                  title: "Nuevo Cliente",
                  icon: "",
                  hiddenInMenu: true,
                  requiredRoles: ['Admin']
                },
                component: () => import(/* webpackChunkName: "cc" */ "@/views/adm/client/CreateClient"),
              },
              {
                path: "/adm/c/:id/edit",
                name: "admin.client.edit",
                meta: {
                  title: "Editar Cliente",
                  icon: "",
                  hiddenInMenu: true,
                  requiredRoles: ['Admin']
                },
                component: () => import(/* webpackChunkName: "cc" */ "@/views/adm/client/EditClient"),
              },
              {
                path: "/adm/c/:id",
                name: "admin.client.view",
                meta: {
                  title: "Detalles del Cliente",
                  icon: "",
                  hiddenInMenu: true,
                  requiredRoles: ['Admin', 'Supervisor']
                },
                component: () => import(/* webpackChunkName: "cc" */ "@/views/adm/client/ClientView"),
              },
            ],
          },
          {
            path: "/adm/t",
            name: "admin.tools",
            meta: {
              title: "Materiales",
              icon: "mdi-card-account-details",
              hiddenInMenu: false,
              group: "adm",
              requiredRoles: ['Admin']
            },
            component: RouteWrapper,
            redirect: "/adm/t",
            children: [
              {
                path: "/adm/t",
                name: "admin.tools.list",
                meta: {
                  title: "Listado de materiales",
                  icon: "",
                  group: "adm",
                  requiredRoles: ['Admin']
                },
                component: () => import(/* webpackChunkName: "tl" */ "@/views/adm/tool/ToolList"),
              },
            ],
          },
        ],
      },
      {
        path: "/eq",
        component: RouteWrapper,
        redirect: "/eq/cl",
        meta: {
          title: "Listados",
          icon: "mdi-format-list-checkbox",
          group: "eq",
          requiredRoles: ['Admin', 'Supervisor']
        },
        children: [
          {
            path: "/eq/cl",
            component: () => import(/* webpackChunkName: "eql" */ "@/views/inventory/equipment/EquipmentList"),
            name: "eq.list",
            meta: {
              title: "Equipos",
              group: "eq",
            },
          },
          {
            path: "/eq/cl/create",
            component: () => import(/* webpackChunkName: "eql" */ "@/views/inventory/equipment/EquipmentView"),
            name: "eq.create",
            meta: {
              title: "Crear equipo",
              group: "eq",
              hiddenInMenu: true,
            },
          },
          {
            path: "/eq/cl/:id",
            component: () => import(/* webpackChunkName: "eql" */ "@/views/inventory/equipment/EquipmentView"),
            name: "eq.view",
            meta: {
              title: "Detalle del equipo",
              group: "eq",
              hiddenInMenu: true,
            },
          },
          {
            path: "/eq/cl/:id/edit",
            component: () => import(/* webpackChunkName: "eql" */ "@/views/inventory/equipment/EquipmentView"),
            name: "eq.edit",
            meta: {
              title: "Editar del equipo",
              group: "eq",
              hiddenInMenu: true,
            },
          },          
          {
            path: "/eq/c",
            component: () => import(/* webpackChunkName: "cl" */ "@/views/inventory/condenser/CondenserList"),
            name: "eq.condensadores.list",
            meta: {
              title: "Condensadores",
              group: "eq",
            },
          },
          {
            path: "/eq/e",
            component: () => import(/* webpackChunkName: "el" */ "@/views/inventory/evaporator/EvaporatorList"),
            name: "eq.evaporadores.list",
            meta: {
              title: "Evaporadores",
              group: "eq",
            },
          },
        ],
      },
    ],
  },
];
