<template>
  <p>{{ currentMessage }}</p>
</template>

<script>
import {
  ApplicationPaths,
  QueryParameterNames,
} from "@/components/api-authorization/ApiAuthorizationConstants";
import { AuthenticationResultStatus } from "@/services/authorizeService";

// The main responsibility of this component is to handle the user's login process.
// This is the starting point for the login process. Any component that needs to authenticate
// a user can simply perform a redirect to this component with a returnUrl query parameter and
// let the component perform the login and return back to the return url.
export default {
  name: "Login",
  data() {
    return {
      message: "",
    };
  },
  computed: {
    currentMessage() {
      return this.message;
    },
  },
  async created() {
    switch (this.$route.path) {
      case ApplicationPaths.Login:
        await this.login(this.getReturnUrl());
        break;
      case ApplicationPaths.LoginCallback:
        await this.processLoginCallback();
        break;
      case ApplicationPaths.LoginFailed:
        break;
      case ApplicationPaths.Profile:
        break;
      case ApplicationPaths.Register:
        break;
      default:
        throw new Error(`Invalid action ${this.$route.path}`);
    }
  },
  methods: {
    async login(returnUrl) {
      const state = { returnUrl };
      const result = await this.auth.signIn(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          await this.navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          this.message = result.message;
          break;
        default:
          throw new Error(`Invalid status result ${result.status}.`);
      }
    },
    async processLoginCallback() {
      const url = window.location.href;
      const result = await this.auth.completeSignIn(url);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          // There should not be any redirects as the only time completeSignIn finishes
          // is when we are doing a redirect sign in flow.
          throw new Error("Should not redirect.");
        case AuthenticationResultStatus.Success:
          await this.navigateToReturnUrl(this.getReturnUrl(result.state));
          break;
        case AuthenticationResultStatus.Fail:
          this.message = result.message;
          break;
        default:
          throw new Error(`Invalid authentication result status '${result.status}'.`);
      }
    },

    getReturnUrl(state) {
      const params = new URLSearchParams(window.location.search);
      const fromQuery = params.get(QueryParameterNames.ReturnUrl);
      if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
        // This is an extra check to prevent open redirects.
        throw new Error(
          "Invalid return url. The return url needs to have the same origin as the current page."
        );
      }
      return (state && state.returnUrl) || fromQuery || `${window.location.origin}/`;
    },
    navigateToReturnUrl(returnUrl) {
      // It's important that we do a replace here so that we remove the callback uri with the
      // fragment containing the tokens from the browser history.
      window.location.replace(returnUrl);
    },
  },
};
</script>

<style scoped></style>
