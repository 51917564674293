import Vue from "vue";
import router from "@/router";
import axios from "@/plugins/axios";
import store from "@/store";
import i18n from "@/i18n";

import vuetify from "@/plugins/vuetify";
import "@/theme/default.sass";

// nprogress
import "nprogress/nprogress.css";

import App from "@/App.vue";
import serviceContainer from "@/service-container";

if (process.env.NODE_ENV === 'production') {
  Vue.config.productionTip = false;
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

Vue.use(axios);

new Vue({
  provide: serviceContainer,
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
