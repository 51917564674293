<template>
  <v-card tile>
    <v-card-title> Notification </v-card-title>
    <v-divider />
    <v-card-text class="pa-0">
      <v-list class="pa-0" two-line>
        <template v-for="(item, index) in items">
          <v-subheader v-if="item.header" :key="item.header">
            {{ item.header }}
          </v-subheader>
          <v-divider v-else-if="item.divider" :key="index" />
          <v-list-item v-else :key="item.title" @click="handleClick">
            <v-list-item-avatar :color="item.color">
              <v-icon dark>
                {{ item.icon }}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                <span v-text="item.title" />
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="caption">
              {{ item.timeLabel }}
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-list>
      <v-divider />
      <v-btn block class="ma-0" text>
        All
      </v-btn>
      <v-divider />
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    data() {
      return {
        items: [],
      };
    },
    methods: {
      handleClick: (e) => {
        console.log(e);
      },
    },
  };
</script>
