<template>
  <v-app-bar app color="primary" dark>
    <v-app-bar-nav-icon @click="handleDrawerToggle"/>
    <v-spacer/>
    <v-toolbar-items>
      <template v-if="isAuthenticated">
        <v-btn class="primary" disabled link text>
          {{ userName }}
        </v-btn>
        <v-btn class="primary" depressed @click="logout()">
          Cerrar sesión
        </v-btn>
      </template>
      <template v-else>
        <v-btn class="primary" depressed @click="login()">
          Inicie sesión
        </v-btn>
      </template>
      <v-btn icon @click="handleFullScreen()">
        <v-icon>mdi-fullscreen</v-icon>
      </v-btn>
      <v-menu
        class="elelvation-1"
        offset-y
        origin="center center"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn slot="activator" icon text v-on="on">
            <v-badge color="red" overlap>
              <span slot="badge">0</span>
              <v-icon medium>
                mdi-bell
              </v-icon>
            </v-badge>
          </v-btn>
        </template>
        <notification-list/>
      </v-menu>
      <v-menu offset-y origin="center center" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn slot="activator" icon large text v-on="on">
            <c-avatar :size="30"/>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item
            v-for="(item, index) in profileMenus"
            :key="index"
            :disabled="item.disabled"
            :href="item.href"
            :target="item.target"
            :to="!item.href ? { name: item.name } : null"
            rel="noopener"
            @click="item.click"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-toolbar slot="extension" color="white" dense light tag="div">
      <v-icon>mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-3"/>
      <v-spacer/>
      <v-btn color="black" icon small>
        <v-icon @click="handleGoBack" v-text="'mdi-arrow-left'"/>
      </v-btn>
    </v-toolbar>
  </v-app-bar>
</template>
<script>
import NotificationList from "@/components/widgets/list/NotificationList";
import Util from "@/util";
import {mapGetters} from "vuex";
import CAvatar from "@/components/avatar/CAvatar";

export default {
  name: "AppToolbar",
  components: {
    CAvatar,
    NotificationList,
  },
  data() {
    return {
      isAuthenticated: false,
      profileMenus: [
        {
          icon: "mdi-account",
          href: "#",
          title: "Profile",
          click: this.handleProfile,
        },
        {
          icon: "mdi-cog",
          href: "#",
          title: "Settings",
          click: this.handleSetting,
        },
        {
          icon: "mdi-power",
          href: "#",
          title: "Logout",
          click: this.handleLogut,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["userName"]),
    breadcrumbs() {
      const { matched } = this.$route;
      return matched.map((route, index) => {
        const to =
          index === matched.length - 1
            ? this.$route.path
            : route.path || route.redirect;
        const text = route.meta.title;
        return {
          text: text,
          to: to,
          exact: true,
          disabled: false,
        };
      });
    },
  },
  async created() {
    this.isAuthenticated = await this.$auth.isAuthenticated();
  },
  methods: {
    handleDrawerToggle() {
      this.$emit("side-icon-click");
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    handleLogut() {
      if (window.confirm("Are you sure to logout?")) {
        this.$store.dispatch("logout");
        this.$emit("SHOW_SNACKBAR", {
          text: "Logout successfull",
          color: "success",
        });
        this.$router.push("/auth/login");
      }
    },
    handleSetting() {
    },
    handleProfile() {
    },
    handleGoBack() {
      this.$router.go(-1);
    },
    login() {
      this.$router.push(
        `/authentication/login?returnUrl=${window.location.href}`
      );
    },
    logout() {
      this.$router.push(
        `/authentication/logout?returnUrl=${window.location.href}`
      );
    },
  },
};
</script>

<style lang="sass" scoped></style>
