import Vue from "vue";
import axios from "axios";
import EventBus from "@/util/eventbus";

import oidc from "@/plugins/oidc";
import authorizeService from "@/services/authorizeService"

Vue.use(oidc);

// create axios
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API || process.env.BASE_URL || "", // api base_url
  timeout: 50000, // timeout,
  headers: { "X-Requested-With": "XMLHttpRequest" },
  // withCredentials: true,
});

const err = (error) => {
  if (error.response === undefined || error.response === null)
    return Promise.reject(error);

  const { status, data } = error.response;
  const { errors } = data;
  let message = [];
  if (typeof errors === "object" && errors !== null) {
    for (let field in errors) {
      message.push(errors[field]);
    }
  } else {
    message.push(errors);
  }

  switch (status) {
    case 400:
      EventBus.$emit("SHOW_SNACKBAR", {
        show: true,
        text: message.join(". "),
        color: "red",
      });
      break;

    case 422:
      EventBus.$emit("SHOW_SNACKBAR", {
        show: true,
        text: message,
        color: "red",
      });

      break;

    case 401:
      EventBus.$emit("AUTH_FAILED");
      break;

    case 403:
      EventBus.$emit("ACCESS_DENIED");
      break;
    case 500:
      EventBus.$emit("SERVER_ERROR");
      break;

    default:
      break;
  }

  return Promise.reject(error);
};

// request interceptor

service.interceptors.request.use(async (config) => {
  const token = await authorizeService.getAccessToken();
  config.headers["Access-Control-Allow-Origin"] = "*";
  config.headers["Content-Type"] ??= "application/json";
  config.headers["Authorization"] = "Bearer " + token;

  return config;
}, err);

// response interceptor

service.interceptors.response.use((response) => {
  if (
    ["put", "post", "delete", "patch"].includes(response.config.method) &&
    response.data.messages
  ) {

    const { data } = response;
    const { messages } = data;
    let message = [];

    if (typeof messages === "object" && messages !== null) {
      for (let field in messages) {
        message.push(messages[field]);
      }
    } else {
      message.push(messages);
    }

    EventBus.$emit("SHOW_SNACKBAR", {
      text: message.join("."),
      color: "success",
    });

    return response;
  }

  if (response.data.error !== undefined) {
    EventBus.$emit("API_FAILED", response.data.error);
  }

  return response;
}, err);

export default service;
